import React, { useState } from "react";

const Postform = () => {
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  async function handleSubmit(event){
    event.preventDefault();
    let jsonInput = JSON.stringify(inputs);

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: jsonInput,
        mode: "no-cors"
    };
    let fetchresult = await fetch('https://worker.pswanso29033.workers.dev/posts', requestOptions);
    window.location.reload();
  }

  return (
    <form className="postform"onSubmit={handleSubmit}>
      <label htmlFor="username">Username</label><br/>
      <input type="text" id="username" name="username" onChange={handleChange} required/><br/>
      <label htmlFor="title">Title</label><br/>
      <input type="text" id="title" name="title" onChange={handleChange} required/><br/>
      <label htmlFor="content">Content</label><br/>
      <textarea rows="5" cols="60" id="content" name="content" onChange={handleChange} required>
      </textarea><br/>
      <input type="submit" value="Submit" />
    </form>
  );
};

export default Postform;
