import React, { useEffect, useState } from "react";

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(
        "https://worker.pswanso29033.workers.dev/posts", {
          method: 'GET',
          headers: {'content-type': 'application/json'}
          }
      );
      const postsResp = await resp.json();
      setPosts(postsResp);
    };

    getPosts();
  }, []);

  return (
    <div className = "page">
      <div className = "header">
        <h1>YikYok</h1>
      </div>

      <div className = "posts">
        {posts.map((post) => (
          <div className="post" key={post.title}>
            <h2>
              {post.title} - {post.username}
            </h2>
            <p>
              {post.content}
            </p>
            <hr></hr>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Posts;
