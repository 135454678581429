import './App.css';
import { Router } from "@reach/router";

import Posts from './components/posts'
import Postform from './components/postform'

function App() {
  return (
    <div>
      <Router>
        <Posts path="/" />
      </Router>

      <div className="postform-wrapper">
        <h2>New Post</h2>
        <Postform/>
      </div>
    </div>
  );
}

export default App;
